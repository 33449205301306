import axios from 'axios'
const API_URL = 'https://nomadiet.gist.kz/api/';
const HEADER ={
    headers:{
      "x-requested-with": "xhr"
  }
}
export const getById = async(id,setInfo)=>{  
    axios.get(`${API_URL}products/${id}`,HEADER)
      .then((res)=>{ setInfo(res) })
      .catch((res)=>{console.log(res)});
}
export default function getAllBaskets(setInfo){  
  axios.get(`${API_URL}baskets`,HEADER)
      .then((res)=>{setInfo(res)})
      .catch((res)=>{console.log(res)});
}
export const getAllBaskets2 = (setInfo)=>{  
  axios.get(`${API_URL}baskets`,HEADER)
      .then((res)=>{setInfo(res)})
      .catch((res)=>{console.log(res)});
}
export const getAllProducts= async(setInfo)=>{  
  axios.get(`${API_URL}products`,HEADER)
      .then((res)=>{setInfo(res)})
      .catch((res)=>{console.log(res)});
}
export const getBasketById= async(id,setInfo)=>{  
  axios.get(`${API_URL}baskets-new/${id}`, HEADER)
      .then((res)=>{setInfo(res)})
      .catch((res)=>{console.log(res)});
}