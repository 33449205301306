import OneElement from "./OneElement";
export default function Element({name,itemName}){
    return(
        <div className="element">
            <p className='nameProducts'>{name}</p>
                <div className="group">
                    {
                       itemName? itemName.map((el,index)=>(
                            <OneElement key={index} elName={itemName[index].name} elSize={itemName[index].amount+" "+itemName[index].unit}/>
                        )):null
                    }
                </div>
        </div>
    )
}