import './Index.css'
import GroupItems from '../set/GroupItems'
export default function IndexSet(
    {
        name,
        products,
        stats,
        ingredients,
        lights
    }
){
    //const[product,setProduct] = useState()
    // useEffect(() => {
    //     getById(id,setProduct)
    // }, [])
    // console.log(id)
//    const [open, setOpen] = useState(false)
    // const w_arr=[
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo
    // ]
    // const arr = [
    //     'Соль',
    //     'Сахар',
    //     'Насыщенные жиры',
    //     'Трансжиры',
    //     'Гликемический индекс'
    // ]  
    // const arr2 = [
    //     '0 г.',
    //     '0 г.',
    //     '0,6 г.',
    //     '0 г.',
    //     '25'
    // ]
    return(
        <div className="index">
            {/* {
               <div className={open==true?"show-modal":"none"}>
                   <div className="box">
                   <NavbarItems setClick={setOpen}/> 
                   <GroupItems ing = {products?products:[]}/>
                   </div>
               </div>
            } */}
            <div className="row align-items-baseline justify-content-around">
                <div className="col-md-5 col-sm-6 col-12 justify-content-center group-content-all">
                    <div className="group-heading-index">  
                        <span className='product-group'>Продуктовый набор - {name}</span>
                        {/* <div className="group-btns">
                                    <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className='btn1'>
                                        <path d="M22 28L14 20L22 12" stroke="#DADADA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <rect x="-1" y="1" width="38" height="38" rx="19" transform="matrix(-1 0 0 1 38 0)" stroke="#DADADA" strokeWidth="2"/>
                                    </svg>                             
                                <svg id="right" width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className='btn2'>
                                    <path d="M18 28L26 20L18 12" stroke="#DADADA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <rect x="1" y="1" width="38" height="38" rx="19" stroke="#DADADA" strokeWidth="2"/>
                                </svg>                    
                        </div> */}
                    </div>
                    <div className="group-content-list">
                        {/* <ListOfProducts w_arr={w_arr}/> */}
                        <div className="total-weight">
                            <span className='desc-weight'>Масса корзины</span>
                            <span className="quan-weight">{stats?stats.total_weight:null} г.</span>
                        </div>
                        <div className="total-weight">
                            <span className='desc-weight'>Цена корзины</span>
                            <span className="quan-weight">{stats?stats.total_price:null}</span>
                        </div>
                        <div className="total-weight">
                            <span className='desc-weight'>Индекс здоровья корзины</span>
                            <span className="quan-weight">{stats?stats.health_factor:null}</span>
                        </div>
                        {/* <div className="show-all" onClick={()=>{setOpen(true)}}>
                            <span className="desc-all">Показать все {products?products.length:null} продуктов</span>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 13L7 7L1 1" stroke="#3F3C3C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div> */}
                    </div>
                    {/* <div className="health-set" id="right">
                        <span className='desc-health-set'>Здоровая корзина</span>
                        <div className="group-desc-set">
                            <span>14 полезных продуктов</span>
                            <div className="hr-small-small"></div>
                            <span>1 вредный</span>
                            <img src={infoCot} alt="cot" className='info-icon'/>
                        </div>8   
                    </div> */}
                </div>
                <div className="col-12 col-sm-7 col-md-7"></div>
                {/* <div className="col-12 col-sm-7 col-md-7">
                    <div className='texts'>
                        <p className='healthIndex'>ИНДЕКС ЗДОРОВЬЯ</p>
                        <p className='productDesc'> Продукт богат ненасыщенными жирными кислотами, витамином Е, клетчаткой, антиоксидантами, омега-3 жирными кислотами</p>
                        <div className='factor'>
                            <span className="span">СОДЕРЖАНИЕ ВРЕДНЫХ ФАКТОРОВ</span>
                            <div className="group-gr">
                               
                                    <span  className="gr">на 100 г продукта</span>
                             
                                <img src={info} alt="icon" className='info-icon'/>
                            </div>
                        </div>
                    </div>
                    <div className='items'>
                    {
                          arr.map((el,index)=>(
                                <Item  key={index} name={arr[index]} howmany={arr2[index]}/>
                          ))
                      }     
                    </div>
                    <Aca/>
                </div> */}
             <GroupItems ing = {products?products:[]}/>
            </div>
        </div>
)}