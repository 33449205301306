import './Aca.css'
export default function Nutri(){
    return(
        <div className='mininutisteppe'>
        <div className="row justify-content-center align-items-center nutristeppe">
            <div className="col-sm-2 col-12">
                <span className='logoName'>NutriSteppe</span>
                <br/>
                <span className='logoDesc'>ВАШ СПУТНИК ЗДОРОВЬЯ</span>
            </div>
            <div className="col-sm-6 col-12">
                   <div className="atlas">
                   <span>
                    Mолекулярный атлас здоровья и персональные рекомендации по здоровому питанию
                    </span>
                   </div>
            </div>
            <div className="col-sm-1 col-0">
                <div className="hr"></div>
            </div>
            <div className="col-sm-1 col-12 powerDIv">
                <span className='power'>POWERED BY<br className='min'/>Nutristeppe<br className='min'/>technology</span>
            </div>
        </div>
        </div>
    )
}