import person from '../../images/Frame.svg'
import './Aca.css'
export default function Aca({text,color}){
    return(
       <div className="aca">
            <div className="row align-items-center logo justify-content-center">
            <div className="col-12 col-sm-2 circle-content">
                <div className="circle">
                            <img src={person} alt="logo" className='img'/>
                        </div>
                </div>
            <div className="col-12 col-sm-9">
           <div className="academic-content">
           <span className="academicText">
                    Заключение Академии профилактической медицины:
            </span>
           </div>
            <div className="polezen" style={{color:color}}>{text}</div> 
            </div>
        </div>
       </div>
    )
}