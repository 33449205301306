import { useHistory } from 'react-router';
import './ListItem.css'
import apple from '../../images/apple (2) 1.png'
export default function BasketItem({nameItem,id,desc,health_factor}){
    const history = useHistory()
    const handleEnter=(e)=>{
        e.preventDefault();
        history.push(`/view/basket/${id}`)
    }
    return(
        <div className='item-product basket-item' onClick={(e)=>(handleEnter(e))}>
               <div className="nav">
                <div className='name-item'>
                    <span>{nameItem}</span>
                </div>
                <div className="iconApple">
                    <img src={apple} alt="" className='iconApple-img'/>
                    <span>{health_factor}</span>
                </div>
                </div>
            <div className="row align-items-center justify-content-between">
                <div className="col-11">
                    <span className='desc'>{desc}</span>
                </div>
                <div className="col-1">
                   <div className="icon">
                   <svg width="10" height="17" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path 
                            d="M1 17L9 9L1 1" 
                            stroke="fff" 
                            strokeWidth="3" 
                            strokeLinecap="round" 
                            strokeLinejoin="round"
                        />
                    </svg>
                   </div>
                </div>
            </div>
        </div>
    )
}