import './Layer.css';
import frame from '../images/frame.png'
export default function QRS(){
const arr = [1,2,3,4,5,6,7,8,9]
    return(
        <div className="qr-group">
         {
             arr.map((el,index)=>(
                <div className="qr-item" key={index}>
                <img src={frame} alt="" />
                <span>Name</span>
                </div>
             ))
         }
        </div>
    )
}