import { useHistory } from 'react-router';
import './ListItem.css'
import group from '../../images/Group 775.svg'
export default function ProductItem({nameItem}){
    const history = useHistory()
    const handleEnter=(e)=>{
        e.preventDefault();
        history.push(`/view/basket/1`)
    }
    return(
        <div className='item-product basket-item' onClick={(e)=>(handleEnter(e))}>
            <div className="row align-items-center justify-content-between">
            <div className="col-3">
                <img src={group} alt="" className='img'/>
                </div>
                <div className="col-8 list-texts">
                    <div className='name-item'>
                    <span>{nameItem}</span>
                    </div>
                </div>   
            </div>
        </div>
    )
}