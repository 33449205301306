import './Element.css'
export default function OneElement({elName,elSize}){
    return(
        <div className='oneElement row justify-content-between align-items-center'>
            <div className="col-7 col-sm-4 align-content-center">
                    <span className="el-name">
                    {elName}
                    </span>
            </div>
           <div className="col-sm-3 col-4 el-size-div align-content-center">
           <span className="el-size">
                {elSize}
            </span>
           </div>
        </div>
    )
}