import { useHistory } from 'react-router';
import './ListItem.css'
import {useState} from 'react'
import apple from '../../images/apple (2) 1.png'
export default function ItemProduct({nameItem,health_factor,priceOb,magnumName,magnumId,weightItem,descItem,idProduct}){
    const history = useHistory()
    const handleEnter=(e)=>{
        e.preventDefault();
        history.push(`/view/product/${idProduct}`)
    }
    return(
        <div className='itemProductTemp'>
            <div className="item_product">
                <div className="list-texts">
                    <div className='name-item'>
                    <span>{nameItem} - 
                    <img src={apple} alt="" className='iconApple-img'/>
                    <span>{health_factor}</span>
                    </span>                 
                    </div>
                   
                </div>
                <div>
                   <div className='we-desc-item'>
                        <span className='weight-item'>{weightItem} грамм</span>
                        <span className='desc-item'>{descItem}</span>
                   </div>
                </div>
            </div>
            <div className="item_product">
                <div className="list-texts">
                    <div className='name-item'>
                        <div className='magnum_name'>{magnumId+" - "+magnumName}</div>
                    </div>
                </div>
                <div>
                  <div> <strong><span>{priceOb} &#8376;</span></strong></div>
                </div>
            </div>
        </div>
    )
}