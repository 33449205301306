import BasketItem from './BasketItem'
import './ListItem.css'
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'
import {useEffect,useState} from 'react'
import getAllBaskets from '../../api'
import Preloader from '../preloader/Preloader'
import bck from '../../images/_Nutristeppe\ Magnum.svg'
export default function GroupsSet(){
    const [basket, setBasket] = useState([])
    const [basket_2, setBasket_2] = useState(false)
    useEffect(()=>{
        getAllBaskets(setBasket)
    },[])
    const handleBasket_2=(id,data)=>{
        if(data){
           for(let t = 0;t <data.length;t++){
               if(data[t].type==id){
                  return <h6>Корзины "Здоровья"</h6>
               }
               if(data[t].type==id){
                 return <h6>Корзины "Семейная"</h6>
             }
           }
        }
    }
    return(
       <div>
           <Navbar/> 
           <img src={bck} className="bck" alt=""/>
           {
        basket ?<div className='basket_groups'>
              <div className="basket-heading"><span>Корзины</span></div>
                  <div className="basket"> 
                        {handleBasket_2(1,basket.data)}
                        <div className="group-baskets">       
                            {
                           basket.data ?  basket.data.map((el,index)=>(
                           <div>{el.type==1?<BasketItem key={basket.data[index].id} health_factor={el.health_factor} nameItem={basket.data[index].name} desc={basket.data[index].description} created={basket.data[index].created_at} updated={basket.data[index].updated_at} id={basket.data[index].id}/>:null}</div>
                           )):<Preloader/>
                            }
                        </div>
                  </div>   
                  <div className="basket"> 
                        { handleBasket_2(2,basket.data)}
                        <div className="group-baskets">       
                            {
                           basket.data ?  basket.data.map((el,index)=>(
                           <div>{el.type==2?<BasketItem key={basket.data[index].id} health_factor={el.health_factor} nameItem={basket.data[index].name} desc={basket.data[index].description} created={basket.data[index].created_at} updated={basket.data[index].updated_at} id={basket.data[index].id}/>:null}</div>
                           )):<Preloader/>
                            }
                        </div>
                  </div>          
                </div>
                
                :<Preloader/>
           }
               <Footer/>
       </div>
       
    )
}