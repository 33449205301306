import Footer from './footer/Footer';
import Navbar from './navbar/Navbar';
import IndexSet from '../layers/index/IndexSet'
import {useState, useEffect} from 'react'
import { getBasketById} from '../api';
import Preloader from './preloader/Preloader';
function Set({match}) { 
  const {
    params: {id}
} = match
  const[info,setInfo] = useState()
  useEffect(() => {
    getBasketById(id,setInfo)
  }, [id])
// const arr = [
//   'МАКРОНУТРИЕНТЫ',
//   'ВИТАМИНЫ',
//   'МИКРОЭЛЕМЕНТЫ',
//   'АМИНОКИСЛОТЫ',
//   'ЖИРНЫЕ КИСЛОТЫ',
//   'ДРУГИЕ ПОКАЗАТЕЛИ'
// ]
console.log(info)
  return (
    <div>
          <Navbar/> 
        {
        info ? 
            <div className='result'>
                    <IndexSet 
                        stats={info?info.data.statistics:null}
                        name={info? info.data.basket_name : ''}
                        products={info? info.data.products : ''}
                        ingredients={info? info.data.ingredients:[]}
                        lights={info?info.data.lights:[]}
                      /> 
                  {/* <Nutri/>
                  <Molecular/>
                  <Element name={arr[0]} itemName={info?info.data.macronutrients:[]}/>
                  <Element name={arr[1]} itemName={info?info.data.vitamins:[]}/>
                  <Element name={arr[2]} itemName={info?info.data.microelements:[]}/>
                  <Element name={arr[3]} itemName={info?info.data.amino_acids:[]}/>
                  <Element name={arr[4]} itemName={info?info.data.fatty_acids:[]}/>
                  <Element name={arr[5]} itemName={info?info.data.other:[]}/> */}
                  {/* {
                    arr.map((el,index)=>(
                      <Element name={arr[index]} key={index} itemName={info?info.data.molecular_properties.amino_acids:[]}/>
                    ))
                  } */}
              
              </div> 
        :<Preloader/>
      }
          <Footer/>
    </div>
  );
}
export default Set;