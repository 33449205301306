import React, {useEffect, useState} from 'react';
import QrReader from 'react-qr-reader';
import './Layer.css';
import {useHistory} from 'react-router-dom'

function Scanner() { 
  const [scanResultWebCam, setScanResultWebCam] =  useState('');
  const handleErrorWebCam = (error) => {
    console.log(error);
  }
  const handleScanWebCam = (result) => {
    if (result){
        setScanResultWebCam(result);
    }
   }
   const history = useHistory();
   const {goBack} = useHistory()
   useEffect(()=>{
     if(scanResultWebCam!==''){   
       history.push(`/view/product/1`);
     }
   },[scanResultWebCam,history])
  return (
                 <div className={scanResultWebCam.length===0?'scan row':'none'}>
                    <div className='scanner'>    
                        <QrReader
                         delay={300}
                         className='qrreader'
                         onError={handleErrorWebCam}
                         onScan={handleScanWebCam}
                         />
                      <div className="row justify-content-center btns">
                          <div className="col-2">
                            <button className='btn btn-primary' onClick={(e)=>{goBack()}}>Отмена</button>
                          </div>
                      </div>
                    </div>
                 </div>
  );
}
export default Scanner;