import Index from './index/Index';
import Navbar from './navbar/Navbar';
import Molecular from './molecular/Molecular'
import Element from './element/Element'
import Footer from './footer/Footer';
import Nutri from './aca/Nutri';
import {useEffect,useState} from 'react'
import './Layer.css'
import { getById } from '../api';
import Preloader from './preloader/Preloader';
function Result({match}) { 
      const {
        params: {id}
    } = match
    const[info,setInfo] = useState()
    useEffect(() => {
      getById(id,setInfo)
    }, [id])

const arr = [
  'МАКРОНУТРИЕНТЫ',
  'ВИТАМИНЫ',
  'МИКРОЭЛЕМЕНТЫ',
  'АМИНОКИСЛОТЫ',
  'ЖИРНЫЕ КИСЛОТЫ',
  'ДРУГИЕ ПОКАЗАТЕЛИ'
]
console.log(info)
  return (
  <div>
      {
        info ? 
        <div className='result'>
      <Navbar/>
      <Index 
          id={info? info.data.id : ''}
          nameProduct={info? info.data.name : ''}
          shopId={info? info.data.shop_id: ''}
          health_index={info? info.data.health_index : ''}
          health_properties={info? info.data.health_properties : []}
          productImage={info? info.data.image : ''}
          lights={info? info.data.lights : []}
          conclusion={info?info.data.conslusion:''}
        /> 
      <Nutri/>
      <Molecular name={info? info.data.name : ''}/>
      <Element name={arr[0]} itemName={info?info.data.macronutrients:[]}/>
      <Element name={arr[1]} itemName={info?info.data.vitamins:[]}/>
      <Element name={arr[2]} itemName={info?info.data.microelements:[]}/>
      <Element name={arr[3]} itemName={info?info.data.amino_acids:[]}/>
      <Element name={arr[4]} itemName={info?info.data.fatty_acids:[]}/>
      <Element name={arr[5]} itemName={info?info.data.other:[]}/>
      {/* {
        arr.map((el,index)=>(
          <Element name={arr[index]} key={index} itemName={info?info.data.molecular_properties.amino_acids:[]}/>
        ))
      } */}
    
  </div> :<Preloader/>
      }
    <div style={{marginTop:'50px'}}>
    <Footer/>
    </div>
       
  </div>
   
  );
}
export default Result;