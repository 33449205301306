import './Index.css'
import GroupItems from '../set/GroupItems'
// import {useState} from 'react'
export default function IndexSet(
    {
        name,
        products,
        weight,
        price
    }
){
    //const[product,setProduct] = useState()
    // useEffect(() => {
    //     getById(id,setProduct)
    // }, [])
    // console.log(id)
   //const [open, setOpen] = useState(false)
    // const w_arr=[
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo,
    //     avo
    // ]
    // const arr = [
    //     'Соль',
    //     'Сахар',
    //     'Насыщенные жиры',
    //     'Трансжиры',
    //     'Гликемический индекс'
    // ]  
    // const arr2 = [
    //     '0 г.',
    //     '0 г.',
    //     '0,6 г.',
    //     '0 г.',
    //     '25'
    // ]
    return(
        <div className="index-com">
            {/* {
               <div className={open==true?"show-modal":"none"}>
                   <div className="box">
                   <NavbarItems setClick={setOpen}/> 
                   <GroupItems ing = {products?products:[]}/>
                   </div>
               </div>
            } */}
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="group-heading-index">  
                        <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill="#7466AA"/>
                                <path d="M29.3536 16.7491L23.358 15.8747L20.6778 10.4218C20.6046 10.2725 20.4841 10.1517 20.3354 10.0782C19.9623 9.89337 19.5089 10.0474 19.3223 10.4218L16.6421 15.8747L10.6465 16.7491C10.4812 16.7728 10.3301 16.851 10.2144 16.9695C10.0745 17.1138 9.99741 17.3079 10.0001 17.5092C10.0027 17.7105 10.0849 17.9025 10.2285 18.043L14.5665 22.2873L13.5416 28.2805C13.5176 28.4199 13.5329 28.5633 13.586 28.6943C13.639 28.8254 13.7276 28.939 13.8417 29.0221C13.9557 29.1053 14.0907 29.1547 14.2314 29.1647C14.372 29.1748 14.5126 29.1451 14.6373 29.0791L20 26.2496L25.3628 29.0791C25.5092 29.1573 25.6792 29.1833 25.8422 29.1549C26.2531 29.0838 26.5293 28.6928 26.4585 28.2805L25.4336 22.2873L29.7715 18.043C29.8896 17.9269 29.9675 17.7752 29.9912 17.6094C30.0549 17.1946 29.7668 16.8107 29.3536 16.7491Z" fill="white"/>
                        </svg>
                        <span className='product-group'>Продуктовый набор - {name}</span>
                        {/* <div className="group-btns">
                                    <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className='btn1'>
                                        <path d="M22 28L14 20L22 12" stroke="#DADADA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <rect x="-1" y="1" width="38" height="38" rx="19" transform="matrix(-1 0 0 1 38 0)" stroke="#DADADA" strokeWidth="2"/>
                                    </svg>                             
                                <svg id="right" width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className='btn2'>
                                    <path d="M18 28L26 20L18 12" stroke="#DADADA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <rect x="1" y="1" width="38" height="38" rx="19" stroke="#DADADA" strokeWidth="2"/>
                                </svg>                    
                        </div> */}
                    </div>
                    <div className="group-content-list">
                        {/* <ListOfProducts w_arr={w_arr}/> */}
                        <div className="total-weight">
                            <span className='desc-weight'>Масса корзины</span>
                            <span className="quan-weight">{weight} г.</span>
                        </div>
                        <div className="total-weight">
                            <span className='desc-weight'>Цена корзины</span>
                            <span className="quan-weight">{price} &#8376;</span>
                        </div>
                        <div className="show-all">
                            <span className="desc-all">Всего {products?products.length:null} продуктов</span>
                            {/* <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 13L7 7L1 1" stroke="#3F3C3C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> */}
                        </div>
                    </div>
                    {/* <div className="health-set" id="right">
                        <span className='desc-health-set'>Здоровая корзина</span>
                        <div className="group-desc-set">
                            <span>14 полезных продуктов</span>
                            <div className="hr-small-small"></div>
                            <span>1 вредный</span>
                            <img src={infoCot} alt="cot" className='info-icon'/>
                        </div>8   
                    </div> */}
                </div>
                <div className="col-12"></div>
                {/* <div className="col-12 col-sm-7 col-md-7">
                    <div className='texts'>
                        <p className='healthIndex'>ИНДЕКС ЗДОРОВЬЯ</p>
                        <p className='productDesc'> Продукт богат ненасыщенными жирными кислотами, витамином Е, клетчаткой, антиоксидантами, омега-3 жирными кислотами</p>
                        <div className='factor'>
                            <span className="span">СОДЕРЖАНИЕ ВРЕДНЫХ ФАКТОРОВ</span>
                            <div className="group-gr">
                               
                                    <span  className="gr">на 100 г продукта</span>
                             
                                <img src={info} alt="icon" className='info-icon'/>
                            </div>
                        </div>
                    </div>
                    <div className='items'>
                    {
                          arr.map((el,index)=>(
                                <Item  key={index} name={arr[index]} howmany={arr2[index]}/>
                          ))
                      }     
                    </div>
                    <Aca/>
                </div> */}
             <GroupItems ing = {products?products:[]}/>
            </div>
        </div>
)}