import Item from '../item/Item'
import './Index.css'
import icon from '../../images/Vector.svg'
import Aca from '../aca/Aca'
import info from '../../images/info.svg'
export default function Index(
        {
            nameProduct,
            shopId,
            health_index,
            health_properties,
            productImage,
            lights,
            conclusion
        }
    ){
    return(
        <div className="index">
            <div className="row align-items-center justify-content-around">
                <div className="col-md-3 col-sm-4 col-12 justify-content-center imageDiv">
                <div className='imageContent'>
                    <img src={productImage} alt='name' className='product-image'/>
                    </div>
                        <div className="productName">
                         <p className='name'>{nameProduct}</p>
                         <p className='shopId'>{shopId}</p>
                        </div>
                </div>
                <div className="col-12 col-sm-7 col-md-7">
                    <div className='texts'>
                        <p className='healthIndex'>ИНДЕКС ЗДОРОВЬЯ</p>
                        <p className='productDesc'>{health_index}</p>
                       <div className='group-property'>
                       {
                            health_properties.map((el,index)=>{
                                return <div key={index} className="property"><span>{health_properties[index]}</span></div>
                            })
                        }
                       </div>
                        <div className='factor'>
                            <span className="span">СОДЕРЖАНИЕ ВРЕДНЫХ ФАКТОРОВ</span>
                            <div className="group-gr">
                                <div className="gr">
                                    <span>на 100 г продукта</span>
                                </div>
                           <img src={info} alt="icon" className='info-icon'/>
                            </div>
                        </div>
                    </div>
                    <div className='items'>
                    {
                          lights.map((el,index)=>(
                                <Item  
                                    key={index} 
                                    name={lights[index].name} 
                                    weight={lights[index].weight}
                                    unit={lights[index].unit}
                                    colorHex = {lights[index].colorHex}
                                />
                          ))
                      }
                  
                    </div>
                    <Aca text={conclusion.text} color={conclusion.color}/>
                </div>
            </div>
        </div>
    )
}